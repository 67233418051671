import React from 'react'
import { tokens } from "../../../theme";
import { Box, useTheme, Typography, IconButton } from "@mui/material";
import ClassCard from './ClassCard';
import Axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Loading from '../../../components/Loading';
import { useSignOut } from "react-auth-kit";
import getCookies from '../../../utils/getCookies';
import { motion, AnimatePresence } from "framer-motion";
import Dropdown from '../../../components/dropdown/Dropdown';
import { generateYears, semesters } from '../../../components/dropdown/DropdownData';
import { getCourseYear, getCourseSemester } from '../../../utils/CourseDuration';
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { generateWeekDatesForTeacherScheduler } from '../../../utils/weekDates';
import { DateTime } from 'luxon';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Classes = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const matches1 = useMediaQuery('(max-width:750px)');
    const season = {'Spring': 1, 'Summer': 2, 'Fall': 3, 'Winter': 4};

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);
    const [courses, setCourses] = React.useState(null);
    
    const [maxYear, setMaxYear] = React.useState(0);
    const [minYear, setMinYear] = React.useState(0);
    const [year, setYear] = React.useState(0); // current year
    // const [semester, setSemester] = React.useState('all');
    const [semester, setSemester] = React.useState('Fall');

    const today = DateTime.now();
    const two_weeks_later = today.plus({ weeks: 2 });
    const weekDates = generateWeekDatesForTeacherScheduler(two_weeks_later, 0.5);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [search, setSearch] = React.useState('');
    const [codeSearch, setCodeSearch] = React.useState('');

    let currentUser = getCookies("_auth_state");

    const id = currentUser ? currentUser.split(',')[0]?.split(':')[1]?.split('"')[1] : null;
    const who = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;

    const userRole = who;
    const userUUID = id;

    const handleAdminHome = () => {
        localStorage.setItem('selectedMenu', 'Dashboard');
        window.location.href = '/admin/home';
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: 'auto',
        maxWidth: '40%',
        maxHeight: '70%',
        backgroundColor: colors.primary[600],
        borderRadius: '10px',
        border: 'none',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        p: 4,
        overflowY: 'auto',
    }; 

    React.useEffect(() => {
        async function getCurrentSemester() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/semesters/current-semester`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setYear(response.data.year);
                setSemester(response.data.semester);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                } else {
                    alert(error.response.data.message || error.response.data.error);
                }
            });
        }

        function fetchMaxMinYears() {
            Axios.get(`${process.env.REACT_APP_URL}/api/v1/semesters/max-min-years`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                    setMaxYear(response.data.max_year);
                    setMinYear(response.data.min_year);
                }).catch((error) => {
                    if (error.response !== null && error.response.status !== 404)
                        console.log(error.response.data.message);
                    else if (error.response !== null && error.response.status === 401)
                        handleLogout();
                });
        }
    
        async function getCourseInfo() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setCourses(response.data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                } else if (error.response.status === 404) {
                    setCourses([]);
                    return;
                } else {
                    alert(error.response.data.message || error.response.data.error);
                }
            });
        }
    
        async function getCoursesByTeacherId() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers/${userUUID}/courses`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setCourses(response.data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                } else if (error.response.status === 404) {
                    setCourses([]);
                    return;
                } else {
                    alert(error.response.data.message || error.response.data.error);
                }
            });
        }

        // here, when we get courses with student's id, we need to filter out the courses that the student is_confirmed as false
        async function getCoursesByStudentId() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/${userUUID}/courses`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setCourses(response.data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                } else if (error.response.status === 404) {
                    setCourses([]);
                    return;
                } else {
                    alert(error.response.data.message || error.response.data.error);
                }
            });
        }

        async function checkTeacherAvailability() {
            // Pass Joon Ssam, prof, and Alex
            if (userUUID === '202fd2a6-6fa6-4537-8587-d59affadbee4' 
                || userUUID === '7a819ca3-0e10-43ca-8ced-6a9cbd841cf9'
                || userUUID === '2a2ec8f8-5976-4fcf-90dc-56d9d2a25166'
                || userUUID === '5690a829-c4ed-424d-be87-8ae6878c02f5'
                || userUUID === '43dab165-f026-4239-976a-ae3d0643bc64'
                || userUUID === '9e67e123-76d5-4bb5-9a88-31b541658ec8') 
                return;
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers/${userUUID}/weekly-schedule/availability`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
                params: {
                    week_start: weekDates[1].date.set({ hour: 0, minute: 0, second: 0 }).toISO(),
                    week_end: weekDates[7].date.set({ hour: 23, minute: 59, second: 59 }).toISO(),
                },
            }).then((response) => {
                if (response.data.teacherAvailabilities.length === 0 || response.data.teacherAvailabilities === undefined) {
                    handleOpen();
                }
            }).catch((error) => {
                console.log(error.response.data.message);
            });
        }

        fetchMaxMinYears();
        if (userRole === "admin") {
            getCourseInfo();
        } else if (userRole === "teacher") {
            getCoursesByTeacherId();
            checkTeacherAvailability();
        } else if (userRole === "student") {
            getCoursesByStudentId();
        }

        getCurrentSemester();
        // setYear(2024);
        // setYear(new Date().getFullYear());
        setLoading(false);

        let intervalId;
        if (userRole === "admin") {
            intervalId = setInterval(getCourseInfo, 60000 * 60); // 1 hour
        } else if (userRole === "teacher" || userRole === "student") {
            intervalId = setInterval(() => {
                if (userRole === "teacher") {
                    getCoursesByTeacherId();
                } else if (userRole === "student") {
                    getCoursesByStudentId();
                }
            }, 60000 * 15); // 15 minutes
        }
        
        return () => clearInterval(intervalId);

    }, [userRole, userUUID]);

    // focus on search code input field if user is admin
    React.useEffect(() => {
        if (userRole === "admin" && !loading) {
            const input = document.getElementById('searchByCode');
            if (input) {
                input.focus();
            }
        }
    }, [loading, courses]);

    return (
        <Box sx={{ height: "100%", width: "100%" }}>
            { loading || courses === null ?
                <Loading />
            : 
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style}>
                            <Typography 
                                id="transition-modal-title" 
                                variant="h4" 
                                component="h2" 
                                sx={{ textAlign: 'center', mb: 3, mt: 3, fontWeight: 'bold', color: 'red' }}
                            >
                                ※ Attention ※
                            </Typography>
                            <Box 
                                sx={{
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    width: '80%', 
                                    margin: '0 auto', 
                                    textIndent: '1rem',
                                    flexGrow: 1,
                                    mb: 2,
                                    fontFamily: 'Source Sans 3',
                                    fontSize: '1rem',
                                    lineHeight: '1.6',
                                }}
                            >
                                <p>
                                    It appears that you have not yet submitted your availability for the upcoming weeks,
                                    <Box component="span"> </Box>
                                    <Box component="span" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                                        {`${weekDates[1].date.set({ hour: 0, minute: 0, second: 0 }).toISO().split('T')[0]} - `}
                                    </Box> 
                                    
                                    <Box component="span" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                                        {` ${weekDates[7].date.set({ hour: 23, minute: 59, second: 59 }).toISO().split('T')[0]}`}
                                    </Box>.
                                </p>
                                <p>
                                    Of course, you will be teaching the classes that have been assigned to you.
                                </p>
                                <p>
                                    Moving forward, any NEW classes (including substitutes) will only be allocated to teachers who have submitted their availability forms.                        
                                </p>
                                <p>
                                    If no availability is provided, we will assume that you are not available to take any NEW classes in the coming weeks.
                                </p>
                                <p>
                                    We kindly ask that you submit your availability before the deadline.
                                </p>
                                <p>
                                    Please click the guideline icon (<HelpOutlineIcon color="primary" sx={{display: 'inline-block', position: 'absolute', marginTop: '3px'}}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;) in the upper right corner and review the instructions on how to submit your availability.
                                </p>
                                <p>
                                    Please feel free to contact A ONE Office if you have any questions or concerns.
                                </p>
                                <p>
                                    Thank you!
                                </p>
                            </Box>   
                                <Typography
                                    variant='h4'
                                    sx={{ 
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        mt: "1rem",
                                    }}
                                >
                                    <span style={{ color: colors.blueAccent[600], marginRight: "0.5rem" }}>
                                        A ONE
                                    </span>
                                    Institute
                                </Typography>                        
                        </Box>
                    </Fade>
                </Modal>
                <Box
                    sx={{ 
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: colors.primary[600],
                        maxWidth: "1200px",
                        m: "0 auto",
                        minHeight: "100%",
                        boxShadow: "0px 0px 10px 10px rgba(0, 0, 0, 0.10)",
                    }}
                >
                    <Box sx={{ height: "4rem", width: "100%" }}></Box>
                    {userRole === "admin" ? (
                        <Button
                            fullWidth
                            variant="contained"
                            color='secondary'
                            onClick={handleAdminHome}
                            sx={{
                                mt: "2rem",
                                ml: "auto",
                                mr: "3rem",
                                width: "14rem",
                            }}
                        >
                            <Typography color={colors.primary[400]}>Go to Admin Dashboard</Typography>
                        </Button>
                    ) : null}
                    {courses.length === 0 ? 
                        <>
                            <Box 
                                sx={{ 
                                    display: "flex", 
                                    justifyContent: "center", 
                                    height: "100%", 
                                    width: "100%", 
                                    fontWeight: "1", 
                                    mt: "15rem" 
                                }}
                            >
                                <ErrorOutlineIcon fontSize="large" sx={{ my: "auto", mr: "1rem" }} />
                                {userRole === "student" ? (
                                    <Typography variant='h2' >
                                        You are not enrolled in any classes.
                                    </Typography>
                                ) : userRole === "teacher" ? (
                                    <Typography variant='h2'>
                                        You are not teaching any classes.
                                    </Typography>
                                ) : userRole === "admin" ? (
                                    <Typography variant='h2' >
                                        There are no classes.
                                    </Typography>
                                ) : null}
                            </Box>
                            <Box
                                sx={{ 
                                    display: "flex", 
                                    justifyContent: "center", 
                                    height: "100%", 
                                    width: "100%", 
                                    fontWeight: "1", 
                                    mt: "1rem" 
                                }}
                            >
                                {(userRole === "student" || userRole === "teacher") ? (
                                    <Typography variant='h5' color='grey' >
                                        Please contact an administrator if your classes are not visible to you.
                                    </Typography>
                                ) : userRole === "admin" ? (
                                    <Typography variant='h5' color='grey' >
                                        Please contact a developer if you don't see any classes.
                                    </Typography>
                                ) : null}
                            </Box>
                        </> : <>
                            <Box
                                sx={{
                                    width: "100%", 
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingX: "2rem",
                                    mt: "2rem",
                                }}
                            >
                                <Typography
                                    variant='h2'
                                    sx={{ 
                                        textAlign: "center",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <span style={{ color: colors.blueAccent[600], marginRight: "0.5rem" }}>
                                        A ONE 
                                    </span>
                                    Classrooms
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "1rem",
                                        mt: "2rem",
                                        minHeight: 68,
                                        ...matches1 && {
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "1rem",
                                            ...matches1 && {
                                                flexDirection: "column",
                                            },
                                        }}
                                    >
                                        {/* SEARCH BAR */}
                                        <Box
                                            display="flex"
                                            backgroundColor={colors.primary[600]}
                                            borderRadius="3px"
                                            boxShadow={10}
                                            mb={2}
                                            maxWidth={200}
                                            minWidth={200}
                                            sx={{
                                                ...matches1 && {
                                                    mb: 0,
                                                },
                                            }}
                                        >
                                            <InputBase sx={{ ml: 2, flex: 1 }} onChange={(e) => setSearch(e.target.value)} placeholder="Search by course title" />
                                            <IconButton type="button" sx={{ p: 1 }}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Box>

                                        <Box
                                            display="flex"
                                            backgroundColor={colors.primary[600]}
                                            borderRadius="3px"
                                            boxShadow={10}
                                            mb={2}
                                            maxWidth={200}
                                            minWidth={200}
                                        >
                                            <InputBase 
                                                sx={{ ml: 2, flex: 1 }} 
                                                onChange={(e) => setCodeSearch(e.target.value)} 
                                                placeholder="Search by course code" 
                                                id='searchByCode'
                                            />
                                            <IconButton type="button" sx={{ p: 1 }}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "1rem",
                                            height: '50px',
                                            ...matches1 && {
                                                mb: "2rem",
                                            },
                                        }}
                                    >
                                        {/* YEAR SELECT */}
                                        <Dropdown 
                                            maxWidth={200} 
                                            maxHeight={3} 
                                            reactUseState={year} 
                                            setReactUseState={setYear} 
                                            inputLabel="Year" 
                                            data={generateYears(minYear, maxYear)} 
                                        />
                                        {/* SEMESTER SELECT */}
                                        <Dropdown 
                                            maxWidth={200} 
                                            maxHeight={3} 
                                            reactUseState={semester} 
                                            setReactUseState={setSemester} 
                                            inputLabel="Semester" 
                                            data={semesters} 
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Box
                                sx={{
                                    backgroundColor: colors.primary[600],
                                    display: "flex",
                                    padding: "2rem",
                                    flexWrap: "wrap",
                                    gap: "2rem",
                                    mb: "16vh",
                                    height: "10%",
                                    justifyContent: "flex-start",
                                    ...!matches1 && {
                                        justifyContent: "center",
                                    },
                                }}
                            > */}
                            <motion.div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                                    rowGap: "1rem",
                                    columnGap: "1rem",
                                    paddingRight: "2rem",
                                    paddingLeft: "2rem",
                                    paddingBottom: "2rem",
                                    justifyItems: "center",
                                    alignItems: "center",
                                }}
                                layout
                            >
                                <AnimatePresence>
                                    {courses
                                        .filter((course) => {
                                            return search === '' 
                                                ? course 
                                                : course.course_title.toLowerCase().includes(search.toLowerCase()) 
                                                    || course.course_title.toLowerCase().includes(search.toLowerCase());
                                        })
                                        .filter((course) => {
                                            return codeSearch === '' 
                                                ? course 
                                                : course.code?.toLowerCase().includes(codeSearch.toLowerCase());
                                        })
                                        .filter((course) => {
                                            return semester === 'all'
                                                ? course
                                                : getCourseSemester(course, year).includes(season[semester]);
                                        })
                                        .filter((course) => {
                                            return year === 0
                                                ? course
                                                : getCourseYear(course).includes(year);
                                        })                                   
                                        .map((course) => {
                                            return (
                                                <ClassCard key={course.id} course={course} />
                                            )
                                        })
                                    }
                                </AnimatePresence>
                            </motion.div>
                            {/* </Box> */}
                        </>
                    }
                </Box>
            </>}
        </Box>
    )
}

export default Classes