import React from "react";
import { Box } from "@mui/material";

const DailyMemoComp = ({
    colors,
    dailyMemo,
}) => {

    const [memo, setMemo] = React.useState(dailyMemo.memo);
    const [memoId, setMemoId] = React.useState(dailyMemo.id);
    const [prevMemo, setPrevMemo] = React.useState(dailyMemo.memo);

    React.useEffect(() => {
        if (dailyMemo && (dailyMemo.memo !== prevMemo || dailyMemo.id !== memoId)) {
            setMemo(dailyMemo.memo);
            setPrevMemo(dailyMemo.memo);
            setMemoId(dailyMemo.id);
        }
    }, [dailyMemo, prevMemo, memoId]);

    return (
        <Box
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100%",
            }}
        >
            <div
                tabIndex={0}
                style={{
                    width: "100%",
                    height: "100%",
                    color: colors.primary[100],
                    fontSize: "11px",
                    lineHeight: "1.2",
                    padding: "5px",
                }}
                dangerouslySetInnerHTML={{ __html: memo }}
            >
            </div>
        </Box>
    );
};

export default DailyMemoComp;
