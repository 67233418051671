import React from 'react';
import Button from '@mui/material/Button';
import { Box, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import TemplateAccodion from './TemplateAccodion';


function MobileMessageTemplatesModal({
    admin_name,
    colors,
    open,
    handleClose,
    socket,
    socketConnected,
    enqueueSnackbar,
    mobileMessageTemplates,
}) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 800,
        maxHeight: '96%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            aria-labelledby="scheduled-messages-modal"
            aria-describedby="scheduled-messages-modal-description"
            open={open}
            onClose={() => {
                handleClose();
            }}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Typography variant="h4" color={colors.primary[450]} mb={2}>
                        Mobile Message Templates
                    </Typography>
                    <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                        }}
                    >
                        <Typography variant="h6" color='primary' fontWeight='bold'>
                            Please use the following variables in the message templates:
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="h6" color={colors.primary[450]}>
                                    Student Name in English = {'{student_name}'}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="h6" color={colors.primary[450]}>
                                    Student Name in Korean = {'{student_name_kor}'}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="h6" color={colors.primary[450]}>
                                    Teacher's First Name in English = {'{teacher_name}'}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="h6" color={colors.primary[450]}>
                                    Course Name = {'{course_name}'}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="h6" color={colors.primary[450]}>
                                    Module Name = {'{module_name}'}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="h6" color={colors.primary[450]}>
                                    Module Time (e.g. 11:00 AM - 1:00 PM) = {'{module_time}'}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="h6" color={colors.primary[450]}>
                                    Module Date (e.g. Oct 14, 1983) = {'{module_date}'}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="h6" color={colors.primary[450]}>
                                    Module Start Time (e.g. 10/14/1983, 1:30 PM) = {'{module_start_time}'}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="h6" color={colors.primary[450]}>
                                    Module End Time (e.g. 10/14/1983, 1:30 PM) = {'{module_end_time}'}
                                </Typography>
                            </li>
                        </ul>
                        <Typography variant="h6" color='error' fontWeight='bold'>
                            Please be careful with typos and make sure to use the variables as they are.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 2,
                            width: "100%",
                            flex: 1,
                        }}
                    >
                        <TemplateAccodion
                            admin_name={admin_name}
                            enqueueSnackbar={enqueueSnackbar}
                            socket={socket}
                            socketConnected={socketConnected}
                            is_create={true}
                        />
                        {mobileMessageTemplates && mobileMessageTemplates.map((template, index) => {
                            return (
                                <TemplateAccodion
                                    key={index}
                                    admin_name={admin_name}
                                    _mobileMessageTemplate={template}
                                    enqueueSnackbar={enqueueSnackbar}
                                    socket={socket}
                                    socketConnected={socketConnected}
                                    is_create={false}
                                />
                            );
                        })}
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                        sx={{ width: "140px", my: 2, mx: "auto" }}
                    >
                        Close
                    </Button>
                </Box>
            </Fade>
        </Modal>
    )
}

export default MobileMessageTemplatesModal