import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Axios from "axios";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 214,
    },
});

const CopyCourseModal = ({ 
    semesters,
    season,
    setErrorAlertMessage,
    setErrorAlertOpen,
    setSuccessAlertMessage,
    setSuccessAlertOpen,
    handleLogout,
    semester_id,
    semester_name,
    course_type,
    setUpdated,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 940,
        height: 700,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
    };

    const [courseList, setCourseList] = React.useState(null);
    const [courseCount, setCourseCount] = React.useState(0);
    const [checkedCourses, setCheckedCourses] = React.useState({});
    const [courseFetching, setCourseFetching] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [semester, setSemester] = React.useState('');

    React.useEffect(() => {
        if (courseFetching) {
            // fetch courses based on selected semester
            async function fetchCourses() {
                await Axios.get(`${process.env.REACT_APP_URL}/api/v1/potential-courses/semesters/${semester}?q=${course_type}`, {
                    headers: {
                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    },
                }).then((response) => {
                    buildCourseList(response.data.potentialSchedule);
                    setCourseFetching(false);
                }).catch((error) => {
                    console.log("error: ", error);
                    if (error.response.status === 404) {
                        setCourseFetching(false);
                        return;
                    } else if (error.response.status === 401) {
                        setCourseFetching(false);
                        handleLogout();
                        return;
                    }
                    setErrorAlertMessage(error.response.data.message || error.response.data.error || "Something went wrong!");
                    setErrorAlertOpen(true);
                    setCourseFetching(false);
                });    
            }

            fetchCourses();
        }
    }, [courseFetching]);

    const handleChange = (event) => {
        setSemester(event.target.value);
        setCourseFetching(true);
    };

    const buildCourseList = (potentialSchedule) => {
        const courseList = {};
        let courseCount = 0;

        // potentailSchedule is an array of objects
        // only care about start_time and end_time, and course_potential_schedules
        for (let i = 0; i < potentialSchedule.length; i++) {
            const potential_schedule = potentialSchedule[i];
            const start_time = potential_schedule.start_time;
            const end_time = potential_schedule.end_time;
            const course_potential_schedules = potential_schedule.course_potential_schedules;

            for (let j = 0; j < course_potential_schedules.length; j++) {
                const course_potential_schedule = course_potential_schedules[j];
                const course = course_potential_schedule.course;
                const coursePotentialScheduleObj = {
                    category: course_potential_schedule.category,
                    days: course_potential_schedule.days,
                    email_template: course_potential_schedule.email_template,
                    order: course_potential_schedule.order,
                    _subject: course_potential_schedule.subject || "",
                    teacher_id: course_potential_schedule.teacher_id,
                    potential_schedule: {
                        start_time: start_time,
                        end_time: end_time,
                        semester_id: semester_id,
                    }
                };
                const courseObj = {
                    id: course.id,
                    code: course.code,
                    course_title: course.course_title,
                    is_potential_course: course.is_potential_course,
                    is_private_course: course.is_private_course,
                    potential_course_type: course.potential_course_type,
                    subject: course.subject,
                    course_potential_schedule: coursePotentialScheduleObj,
                };

                if (!courseList[coursePotentialScheduleObj._subject]) {
                    courseList[coursePotentialScheduleObj._subject] = {};
                }
                if (!courseList[coursePotentialScheduleObj._subject][coursePotentialScheduleObj.category]) {
                    courseList[coursePotentialScheduleObj._subject][coursePotentialScheduleObj.category] = [];
                }
                courseList[coursePotentialScheduleObj._subject][coursePotentialScheduleObj.category].push(courseObj);
                courseCount++;
            }
        }

        setCourseCount(courseCount);
        setCourseList(courseList);
    };

    const handleCopySchedule = async () => {
        // create a new set of courses to copy
        const coursesToCopy = new Set(Object.keys(checkedCourses).filter((key) => checkedCourses[key]));
        const courses = [];

        for (let i = 0; i < Object.keys(courseList).length; i++) {
            const subject = Object.keys(courseList)[i];
            for (let j = 0; j < Object.keys(courseList[subject]).length; j++) {
                const category = Object.keys(courseList[subject])[j];
                for (let k = 0; k < courseList[subject][category].length; k++) {
                    const course = courseList[subject][category][k];
                    if (coursesToCopy.has(course.id.toString())) {
                        courses.push(course);
                    }
                }
            }
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/potential-courses/copy-schedule`, {
            courses_to_copy: courses,
            semester_id: semester_id,
            course_type: course_type,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Schedule copied successfully!");
            setSuccessAlertOpen(true);
            setUpdated(true);
            handleClose();
        }).catch((error) => {
            console.log("error: ", error);
            if (error.response.status === 401) {
                handleLogout();
                return;
            }
            setErrorAlertMessage(error.response.data.message || error.response.data.error || "Something went wrong!");
            setErrorAlertOpen(true);
        });
    };

    return (<>
        <Button
            variant="contained"
            color="primary"
            sx={{ 
                ml: "auto",
            }}
            onClick={() => handleOpen()}
        >
            Copy Schedule
        </Button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Typography
                        variant="h6"
                        sx={{
                            color: colors.primary[100],
                            fontSize: 24,
                            marginBottom: 2,
                        }}
                    >
                        Copy Schedule
                    </Typography>
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                color: colors.primary[100],
                                fontSize: 16,
                                marginBottom: 2,
                            }}
                        >
                            1. Select a semester to copy the schedule from:
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Semester</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={semester}
                                label="Semester"
                                sx={{
                                    maxWidth: 300,
                                }}
                                onChange={handleChange}
                            >
                                {semesters.map((semester) => (
                                    <MenuItem key={semester.id} value={semester.id}>
                                        {semester.year + " " + season[semester.season]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography
                            variant="body1"
                            sx={{
                                color: colors.primary[100],
                                fontSize: 16,
                                marginBottom: 2,
                                marginTop: 2,
                            }}
                        >
                            2. Select courses to copy: {Object.keys(checkedCourses).filter((key) => checkedCourses[key]).length} selected
                        </Typography>
                        {courseFetching ? (
                            <CircularProgress color="primary" />
                        ) : courseFetching === false && courseList ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                    mb: 4,
                                }}
                            >
                                    {Object.keys(courseList).map((subject, index) => (
                                        <Box
                                            key={subject + "-" + index}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color: colors.primary[100],
                                                    fontSize: 20,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {subject}
                                            </Typography>
                                            {Object.keys(courseList[subject]).map((category, idx) => (
                                                <Box
                                                    key={subject + "-" + index + "-" + category + "-" + idx}
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        pl: 2,
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            color: colors.primary[100],
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        {category}
                                                    </Typography>
                                                    <FormGroup row sx={{ pl: 2 }}>
                                                        {courseList[subject][category].map((course, i) => (
                                                            <FormControlLabel 
                                                                key={course.id}
                                                                control={
                                                                    <Checkbox 
                                                                        checked={checkedCourses[course.id] || false} 
                                                                        onChange={(event) => {
                                                                            const newCheckedCourses = { ...checkedCourses };
                                                                            newCheckedCourses[course.id] = event.target.checked;
                                                                            setCheckedCourses(newCheckedCourses);
                                                                        }} 
                                                                    />
                                                                } 
                                                                label={course.code ? "(" + course.code + ") " + course.course_title : course.course_title} 
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </Box>
                                            ))}
                                        </Box>
                                    ))}
                            </Box>
                        ) : courseFetching === false && courseList !== null && courseCount === 0 ? (
                            <Typography
                                variant="body1"
                                sx={{
                                    color: colors.primary[100],
                                    fontSize: 16,
                                    marginBottom: 2,
                                }}
                            >
                                No courses found.
                            </Typography>
                        ) : (
                            <></>
                        )}
                        <CustomWidthTooltip 
                            title={"This will not copy 'combined course' settings, which means that you will have to set up the combined course again."}
                            placement="top" 
                            arrow
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ 
                                    mt: "auto",
                                    ml: "auto",
                                    mr: "auto",
                                    minWidth: 200,
                                }}
                                onClick={() => {handleCopySchedule();}}
                            >
                                Copy to {semester_name}
                            </Button>
                        </CustomWidthTooltip>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    </>);
};

export default CopyCourseModal