import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  flexRender,
  getFacetedRowModel,
  getFacetedUniqueValues,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Stack,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Popover,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

const formatDateToEST = (dateString) => {
  if (!dateString) return '';
  try {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  } catch (error) {
    return dateString;
  }
};

// Field definitions mapping
const FIELD_DEFINITIONS = {
  student_name: { header: 'Student Name', size: 150 },
  student_email: { header: 'Email', size: 200 },
  student_school: { header: 'School', size: 150 },
  student_phonenumber: { header: 'Phone Number', size: 150 },
  student_consultation_number: { header: 'Consultation Number', size: 150 },
  student_parent_email: { header: 'Parent Email', size: 200 },
  student_parent_phonenumber: { header: 'Parent Phone Number', size: 150 },
  teacher_name: { header: 'Teacher Name', size: 150 },
  teacher_email: { header: 'Teacher Email', size: 200 },
  teacher_phonenumber: { header: 'Teacher Phone Number', size: 150 },
  course_code: { header: 'Course Code', size: 120 }, 
  course_subject: { header: 'Course Subject', size: 120 },
  course_name: { header: 'Course Name', size: 120 },
  sc_start_date: { header: 'Start Date', size: 120 },
  sc_end_date: { header: 'End Date', size: 120 },
  course_start_date: { header: 'Course Start Date', size: 120 },
  course_end_date: { header: 'Course End Date', size: 120 },
};

const DataETL = ({ 
  data = [],
  isLoading = false,
  selectedFields = [],
  semester = '',
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [columnVisibility, setColumnVisibility] = useState(
    selectedFields.reduce((acc, field) => ({ ...acc, [field]: true }), {})
  );

  const columns = useMemo(
    () => selectedFields.map(fieldId => ({
      accessorKey: fieldId,
      header: FIELD_DEFINITIONS[fieldId]?.header || fieldId,
      filterFn: (row, columnId, filterValue) => {
        const value = row.getValue(columnId);
        const displayValue = typeof value === 'object' && value !== null 
          ? value[fieldId] || ''
          : value || '';
        return displayValue.toString().toLowerCase().includes(filterValue.toLowerCase());
      },
      size: FIELD_DEFINITIONS[fieldId]?.size || 150,
      cell: info => {
        const value = info.getValue();
        if (fieldId.includes('date')) {
          return formatDateToEST(value);
        }
        if (typeof value === 'object' && value !== null) {
          return value[fieldId] || '';
        }
        return value || '';
      }
    })),
    [selectedFields]
  );

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnVisibilityChange: setColumnVisibility,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const exportToCSV = () => {
    const filteredRows = table.getFilteredRowModel().rows;
    const visibleColumns = table.getVisibleLeafColumns();
    
    const headers = ['Row', ...visibleColumns.map(col => col.columnDef.header)].join(',');
    
    const rows = filteredRows.map((row, index) => {
      const rowValues = visibleColumns.map(col => {
        const value = row.getValue(col.id);
        let processedValue;
        
        if (col.id.includes('date')) {
          processedValue = formatDateToEST(value);
        }
        else if (typeof value === 'object' && value !== null) {
          processedValue = value[col.id] || '';
        } else {
          processedValue = value || '';
        }
        
        if (typeof processedValue === 'string' && (processedValue.includes(',') || processedValue.includes('\n'))) {
          return `"${processedValue.replace(/"/g, '""')}"`;
        }
        return processedValue;
      });
      
      return [index + 1, ...rowValues].join(',');
    });
    
    const BOM = '\uFEFF';
    const csv = BOM + [headers, ...rows].join('\n'); 
    
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    
    link.setAttribute('href', url);
    link.setAttribute('download', `${semester ? `${semester}_` : ''}exported_data.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
};

  return (
    <Box sx={{ p: 3 }}>
      <Stack 
        direction="row" 
        spacing={2} 
        sx={{ mb: 3 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" spacing={2}>
          <Typography variant="h6" component="h2">
            {semester ? `Data for ${semester}` : 'Course Data'}
          </Typography>
        </Stack>
      </Stack>

      <Stack 
        direction="row" 
        spacing={2} 
        sx={{ mb: 3 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={exportToCSV}
          >
            Export Filtered Data
          </Button>
          
          <Tooltip title="Choose columns">
            <IconButton onClick={handleClick}>
              <ViewColumnIcon />
            </IconButton>
          </Tooltip>
        </Stack>

        <Typography variant="body2" color="text.secondary">
          Showing {table.getRowModel().rows.length} results
        </Typography>
      </Stack>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <FormGroup>
            {columns.map(column => (
              <FormControlLabel
                key={column.accessorKey}
                control={
                  <Checkbox
                    checked={columnVisibility[column.accessorKey]}
                    onChange={e => {
                      table.setColumnVisibility(prev => ({
                        ...prev,
                        [column.accessorKey]: e.target.checked,
                      }))
                    }}
                  />
                }
                label={column.header}
              />
            ))}
          </FormGroup>
        </Box>
      </Popover>

      <Stack 
        direction={{ xs: 'column', sm: 'row' }} 
        spacing={2} 
        sx={{ mb: 3 }}
        flexWrap="wrap"
        useFlexGap
      >
        {table.getVisibleLeafColumns().map(column => {
          const facetedUniqueValues = column.getFacetedUniqueValues();
          return (
            <Box key={column.id}>
              {column.columnDef.filterFn === 'equals' ? (
                <FormControl sx={{ minWidth: column.columnDef.size }}>
                  <InputLabel id={`${column.id}-label`}>
                    {column.columnDef.header}
                  </InputLabel>
                  <Select
                    labelId={`${column.id}-label`}
                    value={column.getFilterValue() ?? ''}
                    onChange={e => {
                      column.setFilterValue(e.target.value || undefined);
                    }}
                    label={column.columnDef.header}
                    size="small"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {Array.from(facetedUniqueValues.keys()).map(value => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  label={column.columnDef.header}
                  value={column.getFilterValue() ?? ''}
                  onChange={e => {
                    column.setFilterValue(e.target.value);
                  }}
                  placeholder={`Filter ${column.columnDef.header}...`}
                  size="small"
                  sx={{ minWidth: column.columnDef.size }}
                />
              )}
            </Box>
          );
        })}
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small" stickyHeader>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableCell 
                    key={header.id}
                    sx={{ 
                      fontWeight: 'bold', 
                      backgroundColor: 'action.hover',
                      width: header.column.columnDef.size,
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell 
                  colSpan={table.getVisibleLeafColumns().length}
                  align="center"
                  sx={{ py: 3 }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              table.getRowModel().rows.map(row => (
                <TableRow key={row.id} hover>
                  {row.getVisibleCells().map(cell => (
                    <TableCell 
                      key={cell.id}
                      sx={{ width: cell.column.columnDef.size }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
            {!isLoading && table.getRowModel().rows.length === 0 && (
              <TableRow>
                <TableCell 
                  colSpan={table.getVisibleLeafColumns().length}
                  align="center"
                  sx={{ py: 3 }}
                >
                  No results found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DataETL;