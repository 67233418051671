import React from 'react';
import { Button, Typography, Popover, Box, Tooltip } from '@mui/material';
import ExtraMakeupNumButtonModal from './ExtraMakeupNumButtonModal';


function MakeupButtonMenu({
    userId,
    zoom_id,
    date,
    _time,
    event_idx,
    event,
    enqueueSnackbar,
    colors,
    socketConnected,
    socket,
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleButtonClick = (e) => {
        setAnchorEl(e.currentTarget);

        // need to emit to server to let other users know that this user is viewing the course
        socket.emit("put.activeUsers", {
            admin_uuid: userId,
            zoom_id: zoom_id,
            date: date,
            _time: _time,
            event_idx: event_idx,
            active: true,
        });
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);

        // need to emit to server to let other users know that this user is no longer viewing the course
        socket.emit("put.activeUsers", {
            admin_uuid: userId,
            zoom_id: zoom_id,
            date: date,
            _time: _time,
            event_idx: event_idx,
            active: false,
        });
    };

    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    function getFontSize(start_end_diff) {
        if (!start_end_diff || start_end_diff === "1.00") {
            return "8px";
        } else {
            return "12px";
        }
    }

    function calculateNeededMakeupNum() {
        try {
            const all_cancel_num = event?.all_cancel_num ? parseInt(event?.all_cancel_num) : 0;
            const extra_make_up_num = event?.course?.extra_make_up_num ? parseInt(event?.course?.extra_make_up_num) : 0;
            const all_make_up_num = event?.all_make_up_num ? parseInt(event?.all_make_up_num) : 0;

            return all_cancel_num + extra_make_up_num - all_make_up_num;
        } catch (error) {
            console.error(error);
            return -9999;
        }
    }

    return (
        <>
            <Tooltip 
                title={
                    <Typography sx={{ fontSize: 14 }}>
                        캔슬 모듈 횟수: {event?.all_cancel_num} <br />
                        Extra 보강 횟수: {event?.course?.extra_make_up_num} <br />
                        모든 보강 모듈 횟수: {event?.all_make_up_num} <br />
                        ----------------- <br />
                        보강 필요 횟수: {calculateNeededMakeupNum()} <br />
                        (캔슬 모듈 횟수 + Extra 보강 횟수 - 모든 보강 모듈 횟수)
                    </Typography> 
                } 
                placement="top" 
                arrow
            >
                <Button
                    sx={{
                        fontWeight: 800,
                        fontSize: getFontSize(event.start_end_diff),
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                        padding: (!event.start_end_diff || event.start_end_diff === "1.00") ? "0px" : "6px 8px",
                        textTransform: "none",
                    }}
                    onClick={handleButtonClick}
                >
                    <Typography sx={{ fontSize: getFontSize(event.start_end_diff) }}>
                        보강 필요 횟수: {calculateNeededMakeupNum()}
                    </Typography>
                </Button>
            </Tooltip>
            <Popover
                id={popoverId}
                open={popoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        bgcolor: "background.paper",
                        width: "200px",
                        boxShadow: 4,
                    }}
                >
                    <ExtraMakeupNumButtonModal
                        course={event.course}
                        colors={colors}
                        handlePopOverClose={handlePopOverClose}
                        enqueueSnackbar={enqueueSnackbar}
                        socketConnected={socketConnected}
                        socket={socket}
                    />
                </Box>
            </Popover>
        </>
    )
}

export default MakeupButtonMenu