import React from 'react';
import Button from '@mui/material/Button';
import { Box, Typography, Tooltip, IconButton, CircularProgress } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import Axios from 'axios';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

function FollowUpEmailModal({
    student_course,
    colors,
}) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        maxHeight: '80%',
        overflowY: 'auto',
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const admin_name = localStorage.getItem("userName") || "Unknown Admin";
    const [loading, setLoading] = React.useState(false);
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); };

    const [studentCourse, setStudentCourse] = React.useState(null);

    React.useEffect(() => {
        if (student_course) {
            setStudentCourse(student_course);
        }
    }, [student_course]);

    async function handleYes() {
        if (!studentCourse) {
            alert("Student course not found.");
            return;
        }

        setLoading(true);

        try {
            const date = new Date();
            const follow_up_email_sent_by = studentCourse.follow_up_email_sent_by ? null : admin_name + " on " + date.toDateString();

            await Axios.put(`${process.env.REACT_APP_URL}/api/v1/student-courses/${studentCourse.id}/follow-up-email-sent-by`, {
                follow_up_email_sent_by: follow_up_email_sent_by,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                const updatedStudentCourse = {
                    ...studentCourse,
                    follow_up_email_sent_by: follow_up_email_sent_by,
                };
                setStudentCourse(updatedStudentCourse);
                handleClose();
                setLoading(false);
            }).catch((error) => {
                console.error(error);
                setLoading(false);
                alert("Something went wrong. Please refresh this page and try again.");
            });
        } catch (error) {
            console.error(error);
            setLoading(false);
            alert("Something went wrong. Please refresh this page and try again.");
        }
    }

    return (
        <>
            <IconButton
                sx={{
                    p: '2px !important',
                }}
                onClick={() => {
                    handleOpen();
                }}
            >
                {studentCourse && studentCourse.follow_up_email_sent_by ? (
                    <Tooltip 
                        title={
                            <Typography sx={{ fontSize: 14 }}>
                                {`Follow-up Email Sent by ${studentCourse.follow_up_email_sent_by}`}
                            </Typography> 
                        } 
                        placement="bottom" 
                        arrow
                    >
                        <AlternateEmailIcon 
                            fontSize='small' 
                            color='success'
                        />
                    </Tooltip>
                ) : (
                    <AlternateEmailIcon 
                        fontSize='small' 
                        color='disabled'
                    />
                )}
            </IconButton>
            <Modal
                aria-labelledby="student-search-modal"
                aria-describedby="student-search-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Follow Up Email
                        </Typography>
                        <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                        <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                            {studentCourse && studentCourse.follow_up_email_sent_by ? (
                                <Typography variant="h6" color={colors.primary[450]}>
                                    Do you want to cancel it?
                                </Typography>
                            ) : (
                                <Typography variant="h6" color={colors.primary[450]}>
                                    Did you send a follow-up email to this student?
                                </Typography>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            {loading ? (
                                <Box 
                                    sx={{ 
                                        display: "flex", 
                                        flexDirection: "column", 
                                        alignItems: "center", 
                                        justifyContent: "center", 
                                        mt: 2 
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Button
                                    variant="contained"
                                    color={studentCourse && studentCourse.follow_up_email_sent_by ? "error" : "primary"}
                                    sx={{ width: 200, mt: 2 }}
                                    onClick={() => {
                                        handleYes();
                                    }}
                                >
                                    Yes
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

export default FollowUpEmailModal;