// SetupWizard.jsx
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    FormGroup,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from '@mui/material';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataETL from './dataETL';

const AVAILABLE_FIELDS = {
    student: {
        label: 'Student Information',
        fields: {
            student_name: 'Student Name',
            student_email: 'Email',
            student_school: 'School',
            student_phonenumber: 'Phone Number',
            student_consultation_number: 'Consultation Number',
            sc_start_date: 'Start Date',
            sc_end_date: 'End Date',
        }
    },
    parent: {
        label: 'Parent Information',
        fields: {
            student_parent_email: 'Parent Email',
            student_parent_phonenumber: 'Parent Phone Number',
        }
    },
    teacher: {
        label: 'Teacher Information',
        fields: {
            teacher_name: 'Teacher Name',
            teacher_phonenumber: 'Teacher Phone Number',
            teacher_email: 'Teacher Email',
        }
    },
    course: {
        label: 'Course Information',
        fields: {
            course_code: 'Course Code',
            course_subject: 'Course Subject',
            course_name: 'Course Name',
            course_start_date: 'Course Start Date',
            course_end_date: 'Course End Date',
        }
    }
};

const SetupWizard = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [semesters, setSemesters] = useState([]);
    const [selectedSemesters, setSelectedSemesters] = useState([]);
    const [selectedFields, setSelectedFields] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [tableData, setTableData] = useState(null);
    const [isTableLoading, setIsTableLoading] = useState(false);

    useEffect(() => {
        const fetchSemesters = async () => {
            setIsLoading(true);

            try {
                const response = await Axios.get(`${process.env.REACT_APP_URL}/api/v1/data_course_semesters`, {
                    headers: {
                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    }
                });
                console.log(response.data);
                setSemesters(response.data);
            } catch (err) {
                setError('Failed to load semesters');
                console.error('Error details:', {
                    message: err.message,
                    response: err.response?.data,
                    status: err.response?.status,
                    headers: err.config?.headers
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchSemesters();
    }, []);

    const handleSemesterToggle = (semesterId) => {
        setSelectedSemesters(prev => {
            if (semesterId === 'current') {
                return prev.includes('current') ? [] : ['current'];
            } else {
                const newSelection = prev.filter(id => id !== 'current');
                if (prev.includes(semesterId)) {
                    return newSelection.filter(id => id !== semesterId);
                }
                return [...newSelection, semesterId];
            }
        });
    };

    const handleFieldToggle = (category, field) => {
        setSelectedFields(prev => ({
            ...prev,
            [category]: {
                ...prev[category],
                [field]: !prev[category]?.[field]
            }
        }));
    };

    const getSelectedFieldsCount = () => {
        return Object.entries(selectedFields).reduce((count, [category, fields]) => {
            return count + Object.values(fields || {}).filter(Boolean).length;
        }, 0);
    };

    const handleNext = () => {
        setActiveStep(prev => prev + 1);
    };

    const handleBack = () => {
        setActiveStep(prev => prev - 1);
    };

    const handleLoadData = async () => {
        setIsTableLoading(true);
        setError(null);

        const selectedFieldNames = Object.entries(selectedFields)
            .flatMap(([category, fields]) =>
                Object.entries(fields)
                    .filter(([_, isSelected]) => isSelected)
                    .map(([fieldName]) => fieldName)
            );
        try {
            const response = await Axios.post(
                `${process.env.REACT_APP_URL}/api/v1/data`,
                {
                    semesterIds: selectedSemesters.length === 1 && selectedSemesters[0] === 'current' 
                        ? 'current' 
                        : selectedSemesters,
                    fields: selectedFieldNames
                },
                {
                    headers: {
                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (!response.data) throw new Error('No data received');
            setTableData(response.data);
            handleNext();
        } catch (err) {
            setError(err.message);
            console.error('Error loading data:', err);
        } finally {
            setIsTableLoading(false);
        }
    };

    const getSemesterString = (selectedSemesters, semesters) => {
        if (selectedSemesters.includes('current')) {
            return 'Current Semester';
        }
        if (selectedSemesters.length === 1) {
            const semester = semesters.find(sem => sem.id === selectedSemesters[0]);
            return semester ? `${semester.year} ${semester.season}` : '';
        }
        return `Multiple Semesters (${selectedSemesters.length})`;
    };

    const SemesterSelection = () => (
        <Box sx={{ minWidth: 300, mt: 2 }}>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedSemesters.includes('current')}
                            onChange={() => handleSemesterToggle('current')}
                        />
                    }
                    label="Current (Live)"
                />
                {semesters.map(sem => (
                    <FormControlLabel
                        key={sem.id}
                        control={
                            <Checkbox
                                checked={selectedSemesters.includes(sem.id)}
                                onChange={() => handleSemesterToggle(sem.id)}
                                disabled={selectedSemesters.includes('current')}
                            />
                        }
                        label={`${sem.year} ${sem.season}`}
                    />
                ))}
            </FormGroup>
        </Box>
    );

    const FieldSelection = () => (
        <Box sx={{ mt: 2 }}>
            {Object.entries(AVAILABLE_FIELDS).map(([category, { label, fields }]) => (
                <Paper key={category} sx={{ p: 2, mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        {label}
                    </Typography>
                    <FormGroup>
                        {Object.entries(fields).map(([fieldName, fieldLabel]) => (
                            <FormControlLabel
                                key={fieldName}
                                control={
                                    <Checkbox
                                        checked={selectedFields[category]?.[fieldName] || false}
                                        onChange={() => handleFieldToggle(category, fieldName)}
                                    />
                                }
                                label={fieldLabel}
                            />
                        ))}
                    </FormGroup>
                </Paper>
            ))}

            <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" color="text.secondary">
                    Selected Fields: {getSelectedFieldsCount()}
                </Typography>
            </Box>
        </Box>
    );

    // Steps configuration
    const steps = [
        {
            label: 'Select Semester',
            content: <SemesterSelection />,
            isValid: selectedSemesters.length > 0
        },
        {
            label: 'Choose Fields',
            content: <FieldSelection />,
            isValid: getSelectedFieldsCount() > 0
        },
        {
            label: 'View and Export Data',
            content: tableData && (
                <DataETL
                    data={tableData.data}
                    isLoading={isTableLoading}
                    selectedFields={Object.entries(selectedFields)
                        .flatMap(([category, fields]) =>
                            Object.entries(fields)
                                .filter(([_, isSelected]) => isSelected)
                                .map(([fieldName]) => fieldName)
                        )}
                    semester={getSemesterString(selectedSemesters, semesters)}
                />
            ),
            isValid: true
        }
    ];

    return (
        <Box sx={{ width: '100%', p: 3 }}>
            <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            <Box sx={{ mt: 2, mb: 2 }}>
                {steps[activeStep].content}
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                {activeStep > 0 && (
                    <Button
                        onClick={handleBack}
                        disabled={isLoading || isTableLoading}
                    >
                        Back
                    </Button>
                )}

                {activeStep < steps.length - 1 && (
                    <Button
                        variant="contained"
                        onClick={activeStep === 1 ? handleLoadData : handleNext}
                        disabled={!steps[activeStep].isValid || isLoading || isTableLoading}
                        startIcon={
                            (isLoading || isTableLoading) &&
                            <CircularProgress size={20} color="inherit" />
                        }
                    >
                        {activeStep === 1 ? 'Load Data' : 'Next'}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default SetupWizard;