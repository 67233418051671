import React, {useContext} from 'react';
import { SocketContext } from '../../socket';
import { tokens } from "../../theme";
import { Box, Typography, useTheme, Button } from "@mui/material";
import Chip from '@mui/material/Chip';
import CourseButtonMenu from './courseButtonMenu/CourseButtonMenu';
import StudentButtonMenu from './studentButtonMenu/StudentButtonMenu';
import ModuleButtonMenu from './moduleButtonMenu/ModuleButtonMenu';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TeacherButtonMenu from './teacherButtonMenu/TeacherButtonMenu';
import RateReviewIcon from '@mui/icons-material/RateReview';
import MemoOptionsToolbar from './MemoOptionsToolbar';
import { styled } from '@mui/material/styles';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import MakeupButtonMenu from './makeupButtonMenu/MakeupButtonMenu';

const TimeBlock = ({
    admin_name,
    socketConnected,
    userId,
    zoomRooms,
    zoom, // zoom id needed to get the schedule
    date, // date needed to get the schedule
    day,
    time,
    _time, // _time needed to get the schedule
    idx,
    times_len,
    events,
    socketIoUsers,
    ROW_HEIGHT,
    enqueueSnackbar,
    setStudentForMoreInfo,
    setTeacherForMoreInfo,
    setBackdropOpen,
    handleBackdropClose,
    setCheckedAttendanceList,
    setCheckedTeacherCourseList,
    checkedAttendanceList,
    checkedTeacherCourseList,
    showCheckBox,
    mobileMessageTemplates,
    cellMemoMap,
    notesMap,
    setNotesModalOpen,
    setNotesModalData,
    setDataForRequest,
    classLogUpdatedEventId,
    setClassLogUpdatedEventId,
    // isThisAdminInViewMode,
}) => {
    const socket = useContext(SocketContext);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const textDivRef = React.useRef(null);
    const toolbarRef = React.useRef(null);

    const [memoTextOptionsOpen, setMemoTextOptionsOpen] = React.useState(false);

    const [isEditing, setIsEditing] = React.useState(false);
    const [prevDate, setPrevDate] = React.useState(date);
    const [prevText, setPrevText] = React.useState("");
    const [text, setText] = React.useState("");
    const [cellMemoId, setCellMemoId] = React.useState(null);
    const [cellColor, setCellColor] = React.useState(null);
    const [activeUsersNum, setActiveUsersNum] = React.useState(0);

    if (events.length > 0)
        console.log("events: ", events);

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(
        ({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: colors.grey[900],
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: theme.shadows[5],
                fontSize: 14,
                border: `1px solid ${colors.grey[700]}`,
            },
        })
    );


    React.useEffect(() => {
        if (cellMemoMap && cellMemoMap[date] && cellMemoMap[date][_time] && cellMemoMap[date][_time][zoom ? zoom.id : null]) {
            const cellMemo = cellMemoMap[date][_time][zoom ? zoom.id : null];
            
            if (prevDate !== date) {
                setPrevText(cellMemo.memo);
                setText(cellMemo.memo ? cellMemo.memo : "");
                setPrevDate(date);
            } else if (prevDate === date && prevText !== cellMemo.memo) {
                setPrevText(cellMemo.memo);
                setText(cellMemo.memo ? cellMemo.memo : "");
            }
            
            setCellMemoId(cellMemo.id ? cellMemo.id : null);
            setActiveUsersNum(cellMemo.active_users_num ? cellMemo.active_users_num : 0);
            setCellColor(cellMemo.cell_color ? cellMemo.cell_color : null);
        } else {
            setPrevDate(date);
            setPrevText("");
            setText("");
            setCellMemoId(null);
            setActiveUsersNum(0);
            setCellColor(null);
        }
    }, [cellMemoMap]);

    React.useEffect(() => {
        if (textDivRef.current) {
            textDivRef.current.innerHTML = text;
        }
    }, [text]);

    const handleDoubleClick = (event) => {
        // if (isThisAdminInViewMode) {
        //     return;
        // }

        setIsEditing(true);
        setMemoTextOptionsOpen(true);    

        /**
         * emit to server that this cell is being edited
         */
        try {
            socket.emit("put.activeUsers", {
                is_cell_memo: true,
                admin_uuid: userId,
                date: date,
                _time: _time,
                zoom_id: zoom.id,
                active: true,
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Failed to emit activeUsers", { variant: "error" });
        }

        setTimeout(() => {
            const textarea = document.getElementById(`textarea-${date}-${_time}-${idx}`);
            if (textarea) {
                textarea.innerHTML = text ? text : "";
            }
        }, 10);

        setTimeout(() => {
            const textarea = document.getElementById(`textarea-${date}-${_time}-${idx}`);
            if (textarea) {

                // select text from a window
                var selectedText = window.getSelection();
            
                // create a range
                var selectedRange = document.createRange();

                var lastNode = textarea.childNodes[textarea.childNodes.length - 1];

                if (lastNode === undefined) {
                    textarea.focus();
                    return;
                }

                var lastNodeLength = lastNode.length;

                let count = 0;
                while (lastNodeLength === undefined) {

                    lastNode = lastNode.childNodes[lastNode.childNodes.length - 1];

                    if (lastNode === undefined) {
                        textarea.focus();
                        return;
                    }

                    lastNodeLength = lastNode.length;
                    count++;

                    if (count === 10) {
                        lastNodeLength = 0;
                        break;
                    }
                }

                // set starting position of the cursor in the texts
                selectedRange.setStart(lastNode, lastNodeLength);

                // collapse the range at boundaries
                selectedRange.collapse(true);

                // remove all ranges
                selectedText.removeAllRanges();

                // add a new range to the selected text
                selectedText.addRange(selectedRange);

                textarea.focus();
            }
        }, 20);

        event.preventDefault();
    };

    const handleOneClick = (event) => {
        // focus this div
        const div = event.target;
        if (div) {
            div.focus();
        }

        // event.preventDefault();
    };

    const handleBlur = (event) => {
        // if (isThisAdminInViewMode) {
        //     setIsEditing(false);
        //     setMemoTextOptionsOpen(false);
        //     return;
        // }

        if (toolbarRef.current && toolbarRef.current.contains(event.relatedTarget)) {
            textDivRef.current.focus();
            return;
        }

        setText(textDivRef.current.innerHTML);
        setMemoTextOptionsOpen(false);
        setIsEditing(false);
        /**
         * emit to server that this cell is not being edited
         */
        try {
            socket.emit("put.activeUsers", {
                is_cell_memo: true,
                admin_uuid: userId,
                date: date,
                _time: _time,
                zoom_id: zoom.id,
                active: false,
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Failed to emit activeUsers", { variant: "error" });
        }

        let cellMemoMapUndefined = false;
        if (!cellMemoMap[date] || !cellMemoMap[date][_time] || !cellMemoMap[date][_time][zoom ? zoom.id : null] || !cellMemoMap[date][_time][zoom ? zoom.id : null].cell_color) {
            cellMemoMapUndefined = true;
        }

        if (textDivRef.current.innerHTML === "" 
            && (prevText === undefined || prevText === "") 
            && ((!cellMemoMapUndefined && cellColor === cellMemoMap[date][_time][zoom ? zoom.id : null].cell_color) || (cellMemoMapUndefined && cellColor === null)))
            return;

        if (textDivRef.current.innerHTML !== prevText 
            || (!cellMemoMapUndefined && cellColor !== cellMemoMap[date][_time][zoom ? zoom.id : null].cell_color)
            || (cellMemoMapUndefined && cellColor !== null)) {
            try {
                socket.emit("update.WeeklyCellMemos", {
                    id: cellMemoId ? cellMemoId : null,
                    memo: textDivRef.current.innerHTML,
                    date: date,
                    time: _time,
                    zoom_id: zoom.id,
                    cell_color: cellColor,
                });
            } catch (error) {
                console.error(error);
            }

            // save the text to localStorage for undo
            const prevWeeklyCellMemos = localStorage.getItem("prevWeeklyCellMemos") ? JSON.parse(localStorage.getItem("prevWeeklyCellMemos")) : [];
            prevWeeklyCellMemos.push({
                id: cellMemoId ? cellMemoId : null,
                memo: prevText,
                date: date,
                time: _time,
                zoom_id: zoom ? zoom.id : null,
            });
            localStorage.setItem("prevWeeklyCellMemos", JSON.stringify(prevWeeklyCellMemos));
        }
    };

    function getFontSize(start_end_diff) {
        if (!start_end_diff || start_end_diff === "1.00") {
            return "10px";
        } else {
            return "12px";
        }
    }

    function getStudentBackgroundColor(attendance) {
        if (attendance.zoom_recording_uploaded === true)
            return "#D9EAF7";
        if (attendance.status === 1 || attendance.status === 5)
            return "#FFFF00";

        return null;
    }

    return (
        <Box 
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "inherit",
                width: "inherit",
                position: "relative",
                color: colors.primary[100],
                backgroundColor: cellColor,
            }}
        >
            {activeUsersNum > 0 && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <RateReviewIcon />
                </Box>
            )}

            {/* Memo here */}
            {!isEditing ? (
                <Box 
                    onDoubleClick={handleDoubleClick} 
                    onClick={handleOneClick}
                    tabIndex={-1}
                    sx={{ 
                        width: "100%", 
                        height: "100%",
                        border: "1px solid transparent",
                        lineHeight: "1",
                        fontSize: "16px",
                        // color: colors.blueAccent[600],
                        '&:focus': {
                            border: `1px solid ${colors.grey[700]}`,
                        },
                    }}
                >
                    <div id={`textbox-${date}-${_time}-${idx}`} dangerouslySetInnerHTML={{ __html: text }}></div>
                </Box>
            ) : (
                <div
                    tabIndex={0}
                    onBlur={handleBlur}
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        id={`textarea-${date}-${_time}-${idx}`}
                        contentEditable="true"
                        ref={textDivRef}
                        style={{
                            width: "100%",
                            height: "100%",
                            fontSize: "16px",
                            border: "none",
                            resize: "none",
                            lineHeight: "1",
                            backgroundColor: "transparent",
                            color: colors.primary[100],
                            overflow: "auto",
                            scrollbarWidth: "3px",
                            scrollbarHeight: "3px",
                            '::WebkitScrollbar': {
                                width: "3px",
                                height: "3px",
                            },
                            outline: "0.5px solid " + colors.primary[100],
                            padding: "1px",
                        }}
                    >
                    </div>
                    {memoTextOptionsOpen && 
                        <MemoOptionsToolbar 
                            toolbarRef={toolbarRef} 
                            colors={colors} 
                            isCellMemo={true}
                            isMainMemo={false}
                            cellColor={cellColor}
                            setCellColor={setCellColor}
                        />
                    }
                </div>
            )}
            {events && events.length > 0 ? (
                events.map((event, idx) => {
                    return (
                        <div key={event.id}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    width: "inherit", // it should be parent's width
                                    top: -15,
                                    left: -10,
                                    display: "flex",
                                    flexWrap: "wrap",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "5px",
                                    zIndex: 2,
                                }}
                            >
                                {event.active_users && event.active_users.length > 0 && (
                                    event.active_users.map((user, active_user_idx) => {
                                        if (!socketIoUsers[user]) {
                                            return null;
                                        }

                                        return (
                                            <Chip 
                                                key={active_user_idx}
                                                label={socketIoUsers[user].name} 
                                                size="small" 
                                                sx={{ 
                                                    bgcolor: socketIoUsers[user].color,
                                                    color: 'black',
                                                    border: `1px solid ${colors.primary[100]}`,
                                                }} 
                                            />
                                        )
                                    })
                                )}
                            </Box>
                            <Box
                                key={idx}
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: events.length > 1 ? `${(100 / events.length * idx).toFixed(2)}%` : "0%",
                                    display: "flex",
                                    flexDirection: "column",
                                    height: event.start_end_diff ? `${(event.start_end_diff * (ROW_HEIGHT + 2)).toFixed(2)}px` : `${ROW_HEIGHT + 2}px`,
                                    width: events.length > 1 ? `${(100 / events.length).toFixed(2)}%` : "100%",
                                    border: `1px solid ${colors.grey[600]}`,
                                    backgroundColor: colors.primary[600],
                                    zIndex: 1,
                                }}
                            >
                                <Box
                                    key={idx}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexGrow: 1,
                                        height: "70%",
                                        width: "100%",
                                        // border: `1px solid ${colors.grey[600]}`,
                                        // backgroundColor: colors.primary[600],
                                        // zIndex: 1,
                                        color: event.is_canceled_int && "red !important",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            flex: 1,
                                            display: "grid",
                                            gridTemplateColumns: "1fr",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            border: `1px solid ${colors.grey[600]}`,
                                            // display: "flex",
                                            // flexDirection: "column",
                                            // justifyContent: "center",
                                            // alignItems: "center",
                                            overflow: "auto",
                                            scrollbarWidth: "3px",
                                            scrollbarHeight: "3px",
                                            '::-webkit-scrollbar': {
                                                width: "3px",
                                                height: "3px",
                                            },
                                        }}
                                    >
                                        {/* course title, code, (current module num /total number of modules (현재/전체수업회차)) */}
                                        <Box
                                            sx={{
                                                position: "relative",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderBottom: `1px solid ${colors.grey[600]}`,
                                                width: "100%",
                                                height: "100%",
                                                padding: (!event.start_end_diff || event.start_end_diff === "1.00") ? "0px" : "2px",
                                            }}
                                        >
                                            <CourseButtonMenu 
                                                userId={userId}
                                                zoom_id={event.zoom_id}
                                                date={date}
                                                _time={_time}
                                                event_idx={idx}
                                                event={event} 
                                                enqueueSnackbar={enqueueSnackbar}
                                                colors={colors}
                                                socketConnected={socketConnected}
                                                socket={socket}
                                                classLogUpdatedEventId={classLogUpdatedEventId}
                                                setClassLogUpdatedEventId={setClassLogUpdatedEventId}
                                                // isThisAdminInViewMode={isThisAdminInViewMode}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderBottom: `1px solid ${colors.grey[600]}`,
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        >
                                            <Typography sx={{ fontSize: getFontSize(event.start_end_diff) }}>
                                                {event.course?.code}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                position: "relative",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        >
                                            <ModuleButtonMenu
                                                userId={userId}
                                                zoom_id={event.zoom_id}
                                                date={date}
                                                _time={_time}
                                                event_idx={idx}
                                                event={event}
                                                enqueueSnackbar={enqueueSnackbar}
                                                colors={colors}
                                                socketConnected={socketConnected}
                                                socket={socket}
                                                zoomRooms={zoomRooms}
                                                // isThisAdminInViewMode={isThisAdminInViewMode}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            flex: 3,
                                            display: "grid",
                                            gridTemplateColumns: "repeat(auto-fit, minmax(min(100%/1, max(90px, 100%/4)), 1fr))",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            overflow: "auto",
                                            scrollbarWidth: "3px",
                                            scrollbarHeight: "3px",
                                            '::-webkit-scrollbar': {
                                                width: "3px",
                                                height: "3px",
                                            },
                                        }}
                                    >
                                        {/* students (current number of sessions) (students consultation number) */}
                                        {event.attendances && event.attendances.length > 0 ? (
                                            event.attendances.map((attendance, attendance_idx) => {
                                                return (
                                                    <Box
                                                        key={idx + "-attendance-" + attendance_idx}
                                                        sx={{
                                                            position: "relative",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            width: "100%",
                                                            height: "100%",
                                                            border: `0.1px solid ${colors.grey[600]}`,
                                                            padding: "2px",
                                                            bgcolor: getStudentBackgroundColor(attendance),
                                                        }}
                                                    >
                                                        <StudentButtonMenu
                                                            admin_name={admin_name}
                                                            userId={userId}
                                                            zoom_id={event.zoom_id}
                                                            date={date}
                                                            _time={_time}
                                                            event_idx={idx}
                                                            event={event}
                                                            attendance={attendance}
                                                            enqueueSnackbar={enqueueSnackbar}
                                                            colors={colors}
                                                            socketConnected={socketConnected}
                                                            socket={socket}
                                                            setStudentForMoreInfo={setStudentForMoreInfo}
                                                            setBackdropOpen={setBackdropOpen}
                                                            handleBackdropClose={handleBackdropClose}
                                                            setCheckedAttendanceList={setCheckedAttendanceList}
                                                            checkedAttendanceList={checkedAttendanceList}
                                                            attendance_len={event.attendances.length}
                                                            showCheckBox={showCheckBox}
                                                            mobileMessageTemplates={mobileMessageTemplates}
                                                            notesMap={notesMap}
                                                            setNotesModalOpen={setNotesModalOpen}
                                                            setNotesModalData={setNotesModalData}
                                                            setDataForRequest={setDataForRequest}
                                                            // isThisAdminInViewMode={isThisAdminInViewMode}
                                                        />
                                                    </Box>
                                                )
                                            })
                                        ) : (<>
                                            {Array(1).fill(0).map((_, idx) => {
                                                return(
                                                    <Box
                                                        key={idx}
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            border: `0.1px solid ${colors.grey[600]}`,
                                                            width: "100%",
                                                            height: "100%",
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: getFontSize(event.start_end_diff) }}>
                                                            No students
                                                        </Typography>
                                                    </Box>
                                                )
                                            })}
                                        </>)}
                                    </Box>
                                    <Box
                                        sx={{
                                            flex: 1,
                                            display: "grid",
                                            gridTemplateColumns: "1fr",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            border: `1px solid ${colors.grey[600]}`,
                                            textAlign: "center",
                                            overflow: "auto",
                                            scrollbarWidth: "3px",
                                            scrollbarHeight: "3px",
                                            '::-webkit-scrollbar': {
                                                width: "3px",
                                                height: "3px",
                                            },
                                        }}
                                    >
                                        {/* teachers, private or group,  */}
                                        <Box
                                            sx={{
                                                position: "relative",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderBottom: `1px solid ${colors.grey[600]}`,
                                                width: "100%",
                                                height: "100%",
                                                // bgcolor: event.is_recorded && "yellow",
                                            }}
                                        >
                                            {event.course?.teacher_courses && event.course?.teacher_courses.length > 0 && event.course?.teacher_courses.map((teacher_course, teacher_course_idx) => {
                                                return (
                                                    <TeacherButtonMenu
                                                        key={idx + "-teacher-" + teacher_course_idx}
                                                        admin_name={admin_name}
                                                        userId={userId}
                                                        zoom_id={event.zoom_id}
                                                        date={date}
                                                        _time={_time}
                                                        event_idx={idx}
                                                        event={event}
                                                        teacher={null}
                                                        teacher_course={teacher_course}
                                                        teacher_course_idx={teacher_course_idx}
                                                        enqueueSnackbar={enqueueSnackbar}
                                                        colors={colors}
                                                        socketConnected={socketConnected}
                                                        socket={socket}
                                                        setTeacherForMoreInfo={setTeacherForMoreInfo}
                                                        setBackdropOpen={setBackdropOpen}
                                                        handleBackdropClose={handleBackdropClose}
                                                        setCheckedTeacherCourseList={setCheckedTeacherCourseList}
                                                        checkedTeacherCourseList={checkedTeacherCourseList}
                                                        showCheckBox={showCheckBox}
                                                        mobileMessageTemplates={mobileMessageTemplates}
                                                        // isThisAdminInViewMode={isThisAdminInViewMode}
                                                    />
                                                )
                                            })}
                                            {event.teacher_id !== null && event.teacher !== undefined && event.teacher !== null && (
                                                <TeacherButtonMenu
                                                    key={idx + "-teacher-teacher" + event.teacher_id}
                                                    admin_name={admin_name}
                                                    userId={userId}
                                                    zoom_id={event.zoom_id}
                                                    date={date}
                                                    _time={_time}
                                                    event_idx={idx}
                                                    event={event}
                                                    teacher={event.teacher ? event.teacher : null}
                                                    teacher_course={null}
                                                    teacher_course_idx={null}
                                                    enqueueSnackbar={enqueueSnackbar}
                                                    colors={colors}
                                                    socketConnected={socketConnected}
                                                    socket={socket}
                                                    setTeacherForMoreInfo={setTeacherForMoreInfo}
                                                    setBackdropOpen={setBackdropOpen}
                                                    handleBackdropClose={handleBackdropClose}
                                                    setCheckedTeacherCourseList={setCheckedTeacherCourseList}
                                                    checkedTeacherCourseList={checkedTeacherCourseList}
                                                    showCheckBox={showCheckBox}
                                                    mobileMessageTemplates={mobileMessageTemplates}
                                                    // isThisAdminInViewMode={isThisAdminInViewMode}
                                                />
                                            )}
                                            {(!event.course?.teacher_courses || event.course?.teacher_courses && event.course?.teacher_courses.length === 0) && event.teacher_id === null && (
                                                <TeacherButtonMenu
                                                    admin_name={admin_name}
                                                    userId={userId}
                                                    zoom_id={event.zoom_id}
                                                    date={date}
                                                    _time={_time}
                                                    event_idx={idx}
                                                    event={event}
                                                    teacher={null}
                                                    teacher_course={null}
                                                    teacher_course_idx={null}
                                                    enqueueSnackbar={enqueueSnackbar}
                                                    colors={colors}
                                                    socketConnected={socketConnected}
                                                    socket={socket}
                                                    setTeacherForMoreInfo={setTeacherForMoreInfo}
                                                    setBackdropOpen={setBackdropOpen}
                                                    handleBackdropClose={handleBackdropClose}
                                                    setCheckedTeacherCourseList={setCheckedTeacherCourseList}
                                                    checkedTeacherCourseList={checkedTeacherCourseList}
                                                    showCheckBox={showCheckBox}
                                                    mobileMessageTemplates={mobileMessageTemplates}
                                                    // isThisAdminInViewMode={isThisAdminInViewMode}
                                                />
                                            )}
                                            {/* <Typography sx={{ fontSize: getFontSize(event.start_end_diff) }}>
                                                {event && event.course && event.course.teacher_courses && event.course.teacher_courses.length > 0 ? (
                                                    event.course.teacher_courses[0].teacher.first_name + " " + event.course.teacher_courses[0].teacher.last_name
                                                ) : (
                                                    "n/a"
                                                )}
                                            </Typography> */}
                                        </Box>
                                        <Box
                                            sx={{
                                                position: "relative",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderBottom: `1px solid ${colors.grey[600]}`,
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    top: 0,
                                                    right: 0,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    zIndex: 1,
                                                }}
                                            >
                                                {event.course && event.course.course_memo_3 && event.course.course_memo_3 !== "" && (
                                                    <LightTooltip 
                                                        title={
                                                            event.course.course_memo_3.split("\n").map((item, key) => {
                                                                return <span key={key}>{item}<br/></span>
                                                            })
                                                        } 
                                                        placement="right-start"
                                                        arrow
                                                    >
                                                        <ModeCommentIcon fontSize='small' color='info' />
                                                    </LightTooltip>
                                                )}
                                            </Box>
                                            <Typography sx={{ fontSize: getFontSize(event.start_end_diff) }}>
                                                {event.course?.is_private_course ? "One-on-One" : "Group"}
                                            </Typography>
                                            {event.course?.is_private_course ? (
                                                <Tooltip title="Student Cancel / Teacher Cancel" placement="bottom" arrow>
                                                    <Typography 
                                                        sx={{ 
                                                            fontSize: getFontSize(event.start_end_diff),
                                                        }}
                                                    >
                                                        sc {event.course?.num_of_student_cancel} / tc {event.course?.num_of_teacher_cancel}
                                                    </Typography>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Teacher Cancel" placement="bottom" arrow>
                                                    <Typography 
                                                        sx={{ 
                                                            fontSize: getFontSize(event.start_end_diff),
                                                        }}
                                                    >
                                                        tc {event.course?.num_of_teacher_cancel}
                                                    </Typography>
                                                </Tooltip>
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        >
                                            <MakeupButtonMenu
                                                userId={userId}
                                                zoom_id={event.zoom_id}
                                                date={date}
                                                _time={_time}
                                                event_idx={idx}
                                                event={event}
                                                enqueueSnackbar={enqueueSnackbar}
                                                colors={colors}
                                                socketConnected={socketConnected}
                                                socket={socket}
                                                // isThisAdminInViewMode={isThisAdminInViewMode}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </div>
                    )
                })
            ) : (<>
            </>)}
        </Box>
    )
}

export default TimeBlock;