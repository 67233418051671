import React from 'react'
import { tokens } from "../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Zoom from '@mui/material/Zoom';
import { Divider } from '@mui/material';
import dayjs from 'dayjs';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import StudentCardModal from './StudentCardModal';
import Axios from 'axios';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        left: 0,
        top: 0,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        width: '1rem',
        cursor: 'pointer',
    },
}));

const StudentChip = ({ 
    course,
    student_course,
    noteCount, 
    attendance_map,
    setInfoUpdated, 
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [student, setStudent] = React.useState(null);

    React.useEffect(() => {
        const getStudent = async () => {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/${student_course.student.uuid}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setStudent(response.data);
            }).catch((error) => {
                console.log(error);
            });
        }

        getStudent();
    }, [student_course.student.uuid]);

    // for modal
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);

    // custom tooltip
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            // backgroundColor: '#CBF7FD',
            backgroundColor: colors.greenAccent[900],
            color: 'rgba(0, 0, 0, 1)',
            maxWidth: 400,
            padding: '1rem',
            borderRadius: '1rem',
            border: `3px solid ${colors.greenAccent[300]}`,
        },
    }));

    // for tooltip
    const [open, setOpen] = React.useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };
    const boundingElement = React.useRef();

    const deleteNote = async (e, attendance_id) => {
        e.stopPropagation();
        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/attendance/${attendance_id}/notes`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message);
            setInfoUpdated(true);
        }).catch((error) => {
            console.log(error);
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message || error.response.data.error);
        });
    };

    const noteColor = (status) => {
        if (status === 1) {
            // present
            return "success.main";
        } else if (status === 2 || status === 5) { // status 5 is late + present
            // late
            return "warning.main";
        } else if (status === 3 || status === 4) {
            // absent
            return "error";
        } else {
            return null;
        }
    };

    const makeTitle = () => {
        const notesArray = [];
        
        if (attendance_map === undefined || attendance_map[student_course.student.id] === undefined || attendance_map[student_course.student.id] === null)
            return null;

        const attendance_by_student = attendance_map[student_course.student.id][course.id];

        if (attendance_by_student === undefined || attendance_by_student === null)
            return null;

        Object.values(attendance_by_student).map((value) => {
            if (value.note !== null && value.note !== "")
                notesArray.push([value.date, value.note, value.status, value.id]);
        })

        if (notesArray.length === 0)
            return null;

        notesArray.sort((a, b) => {
            return dayjs(a[0]) < dayjs(b[0]) ? -1 : 1;
        });

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 20,
                }}
            >
                {notesArray.map((note, index) => {
                        return (
                            <Box 
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    width: '100%',
                                }}
                            >
                                {index !== 0 && <Divider sx={{ width: '100%', my: 1 }} />}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Typography variant='body1' color={noteColor(note[2])} fontWeight="bold">
                                        {note[0]}: 
                                    </Typography>
                                    <DeleteForeverIcon 
                                        color='error'
                                        fontSize='small' 
                                        sx={{ 
                                            cursor: 'pointer',
                                        }} 
                                        onClick={(e) => deleteNote(e, note[3])}
                                    />
                                </Box>
                                <Typography variant='body1' color={noteColor(note[2])}>
                                    {note[1]?.split('\n').map((item, index) => {
                                        // return <span key={index}>{index + 1}. {item}<br /></span>
                                        return <span key={index}>{item}<br /></span>
                                    })}
                                </Typography>
                            </Box>
                        )
                    })
                }
            </Box>
        )
    }

    const studentName = (student) => {
        if (student === null)
            return null;

        let studentName = "";
        if (student.first_name)
            studentName += student.first_name;
        if (student.korean_name)
            studentName += " " + student.korean_name;
        if (student.last_name) {
            const last_name_len = student.last_name.length;
            if (studentName.length + last_name_len < 20)
                studentName += " " + student.last_name;
            else
                studentName += " " + student.last_name.substring(0, 1) + ".";
        }
        
        if (studentName === "")
            return "Unknown";
        
        if (student.consultation_number) {
            return (
                <>
                    <span>
                        {studentName}
                    </span>
                    <br />
                    <span>
                        ({student.consultation_number})
                    </span>
                </>
            )
        }

        return studentName;
    };
    
    return (<>
        {student !== null && <>
        <Chip
            label={ dayjs(student_course.end_date).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD') ?
                <s>{studentName(student)}</s> :
                studentName(student)
            }
            onClick={handleModalOpen}
            sx={{ 
                m: 1,
                opacity: dayjs(student_course.end_date).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD') ? 0.4 : 1,
                cursor: 'pointer',
            }}
        />
        <StudentCardModal 
            modalOpen={modalOpen} 
            setModalOpen={setModalOpen} 
            student={student} 
            setStudentUpdated={setInfoUpdated} 
            setSuccessAlertOpen={setSuccessAlertOpen}
            setSuccessAlertMessage={setSuccessAlertMessage}
            setErrorAlertOpen={setErrorAlertOpen}
            setErrorAlertMessage={setErrorAlertMessage}
        />
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div ref={boundingElement}>
                <HtmlTooltip
                    PopperProps={{
                        disablePortal: true,
                        style: { marginTop: '1rem' }
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={makeTitle()}
                    TransitionComponent={Zoom}
                    placement="bottom"
                    arrow
                >
                    <StyledBadge 
                        badgeContent={
                            noteCount(course, student_course.student.id) > 0 ? noteCount(course, student_course.student.id) : null
                        } 
                        color="success"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClick={handleTooltipOpen}
                    >
                        <StickyNote2OutlinedIcon 
                            sx={noteCount(course, student_course.student.id) === 0 ? { cursor: 'default' } : { cursor: 'pointer' }}
                            onClick={handleTooltipOpen} 
                            color={noteCount(course, student_course.student.id) === 0 ? 'disabled' : 'primary'} 
                        />
                    </StyledBadge>
                </HtmlTooltip>
            </div>
        </ClickAwayListener>
        </>}
    </>)
}

export default StudentChip