import React from 'react';
import { Box, Typography, useTheme, Button } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { tokens } from "../../../theme";
import { Editor } from '@tinymce/tinymce-react';

const options = {
    height: 800,
    menubar: false,
    plugins: [
        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
        'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
    ],
    toolbar: [
        ' undo redo | blocks | bold italic forecolor backcolor fontsize | fontselect |' +
        'alignleft aligncenter alignright alignjustify | lineheight |' +
        'bullist numlist outdent indent | removeformat | help'
    ],
    font_formats: 'Arial=arial,helvetica,sans-serif;' +
                 'Arial Black=arial black,avant garde;' +
                 'Courier New=courier new,courier;' +
                 'Georgia=georgia,palatino;' +
                 'Tahoma=tahoma,arial,helvetica,sans-serif;' +
                 'Times New Roman=times new roman,times;' +
                 'Verdana=verdana,geneva;' +
                 'Impact=impact,chicago;' +
                 'Comic Sans MS=comic sans ms,sans-serif;' +
                 'Trebuchet MS=trebuchet ms,geneva;',
    htmlAllowedTags:  ['.*'],
    htmlAllowedAttrs: ['.*'],
    extended_valid_elements: '*[.*]',
    content_style: "p { margin: 0; }",
};

const DailyMemoModal = ({
    userId,
    socket,
    socketConnected,
    dailyMemoModalOpen,
    setDailyMemoModalOpen,
    dailyMemoModalData,
    setDailyMemoModalData,
    enqueueSnackbar,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [dailyMemoId, setDailyMemoId] = React.useState(null);
    const [dailyMemo, setDailyMemo] = React.useState(null);

    React.useEffect(() => {
        if (dailyMemoModalOpen && dailyMemoModalData) {
            /**
             * emit to server that this daily memo is being edited
             */
            try {
                socket.emit("put.activeUsers", {
                    is_daily_memo: true,
                    admin_uuid: userId,
                    active: true,
                    daily_memo_id: dailyMemoModalData.id,
                });
            } catch (error) {
                console.error(error);
                enqueueSnackbar("Failed to emit activeUsers", { variant: "error" });
            }

            setDailyMemo(dailyMemoModalData.memo);
            setDailyMemoId(dailyMemoModalData.id);
        }
    }, [dailyMemoModalData, dailyMemoModalOpen]);

    const handleClose = () => {

        /**
         * emit to server that this cell is not being edited
         */
        try {
            socket.emit("put.activeUsers", {
                is_daily_memo: true,
                admin_uuid: userId,
                active: false,
                daily_memo_id: dailyMemoId,
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Failed to emit activeUsers", { variant: "error" });
        }

        setDailyMemo(null);
        setDailyMemoId(null);
        setDailyMemoModalData(null);
        setDailyMemoModalOpen(false);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        maxHeight: '80%',
        overflowY: 'auto',
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={dailyMemoModalOpen}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={dailyMemoModalOpen}>
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: 20,
                        }}
                    >
                        <Typography variant='h5' color={colors.primary[100]} fontWeight="bold" sx={{ mb: 2 }}>
                            Daily Memo
                        </Typography>
                        <Editor
                            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                            init={options}
                            value={dailyMemo}
                            onEditorChange={
                                (newValue, editor) => {
                                    setDailyMemo(newValue);
                                }
                            }
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (!socketConnected) {
                                    enqueueSnackbar('Socket not connected. Please refresh the page.', { variant: 'error' });
                                    return;
                                }

                                try {
                                    // save the text to localStorage for undo
                                    const prevWeeklyDailyMemos = localStorage.getItem("prevWeeklyDailyMemos") ? JSON.parse(localStorage.getItem("prevWeeklyDailyMemos")) : [];
                                    prevWeeklyDailyMemos.push({
                                        id: dailyMemoModalData.id ? dailyMemoModalData.id : null,
                                        memo: dailyMemoModalData.memo,
                                    });
                                    localStorage.setItem("prevWeeklyDailyMemos", JSON.stringify(prevWeeklyDailyMemos));

                                    socket.emit("update.WeeklyDailyMemos", {
                                        id: dailyMemoId,
                                        memo: dailyMemo,
                                    });

                                    handleClose();
                                } catch (error) {
                                    console.error('Error saving daily memo: ', error);
                                    enqueueSnackbar('Error saving daily memo.', { variant: 'error' });
                                }
                            }}
                            sx={{
                                mt: 2,
                                width: 100,
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export default DailyMemoModal;